import styled from "styled-components";
import { ImageUrl } from "../../utils/constants";

const SliderContainer = styled.div`
  width: 100%;

  .slick-slider {
    width: 100%;
    margin: ${({marginValue}) => marginValue ? marginValue : '20px 0 10px'};
    padding: ${({padding}) => padding ? padding : ''};
    box-shadow: none;

    .slick-slide{
      margin: 0px;
      display: flex;
      justify-content: center;
      display: ${({display}) => display ? display : 'block'};
      justify-content: ${({content}) => content ? content : ''};
      margin: ${({margin}) => margin ? margin : ''};
      @media (max-width: 600px){
        margin: 0 auto;
      }
    }
    .slick-prev {
      display:none !important;
    }

    .slick-next {
      display:none !important;
    }
      [dir="rtl"] .slick-next:before {
        content: "‹";
      }
  }
`;

const Layout = styled.div`
  width: 100%;
  border-radius:6px;
  padding: ${({ padding }) => (padding ? padding : "0 0 25px 0")};
  margin: ${({ margin }) => (margin ? margin : "0 0 25px 0")};
  .cardWrapper{
    width:100%;
    display:flex;
    flex-wrap:wrap;
    padding-left:0px;
    margin-top:25px;
    .cardDiv{
      width:399px;
    }
  }
  .arrows{
    display:flex;
    width:auto 0 auto auto;
    .iconBackground{
      width:32px;
      height:32px;
      border-radius:3px;
      background: rgba(0, 122, 177, 0.05);
      cursor:pointer;
      display:flex;
      justify-content:center;
      align-items:center;
    }
    .text{
      color:#007AB1;
      text-align: center;
      font-family: Rubik;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin: auto 20px auto 20px;
      display:flex;
    }
  }
.headingMain{
  width:100%;
  display:flex;
  justify-content:space-between;
  padding: 0px 0px 0px 0px;
  .heading{
    font-family: 'Rubik-Medium';
    font-size: 18px;
    line-height: 24px;
    color:#005c87;
    align-items: center;
    display: flex;
  }
  .seeAll{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #005c87;
    text-decoration: underline;
    cursor:pointer;
  }
}
.card{
  width: ${({ width }) => (width ? "399px !important" : "100%")};
}
`;

const Container = styled.div`
  width: 100%;
  display:flex;
  flex-wrap:wrap;
  // padding: 0px 15px;
  .card{
    max-width: 1246px;
    border:none;
  }
  .cardiv{
    max-width: 1246px;
    margin:auto;
    margin-top:25px;
  }
`;

const RecommendedContainer = styled.div`
// margin:15px 0px 30px 0px;
width:1248px;
.row{
  display:flex;
  justify-content:center;
  width:100%;
  margin:0;
  .col-1{
    width:496px;
    background:white;
    border-radius: 4px 0 0 4px;
    .type{
      display:flex;
      margin:20px;

    .type-text{
      border-radius: 6px;
      background: rgba(0, 92, 135, 0.60);
      color:#FFF;
      font-family: Rubik-Regular;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; 
      padding:5px 15px;
      text-transform:capitalize;
     }
    }

    .details{
      display:flex;
      flex-direction:column;
      justify-content:center;
      height:100%;
      margin-left:44px;
      .title{
        color:  #005C87;
        font-family: Rubik-Medium;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }
      .challengeType{
        width:100%;
        display:flex;
        margin-top:25px;
        .icon{
          height:24px;
          width:auto;
          display:flex;
          >img{
            width:100%;
            height:100%;
          }
        }
        .type{
          font-family: Rubik;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          text-align: center;
          color: #005c87;
          display:flex;
          align-items:center;
          margin:0;
          padding-left:10px;
        }
      }
      .description{
        color:  #005C87;
        font-family: Rubik;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        margin: 28px 0px;
        -webkit-line-clamp: 8;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        display: -webkit-box !important;
        overflow: hidden;
      }
      .moving-btn{
        align-items:center;
        flex-shrink: 0;
        display:flex;
        button{
          width: 352px;
          height: 48px;
          border-radius: 4px;
          background: #007AB1;
          ${'' /* box-shadow: 0px 8px 24px 0px rgba(0, 92, 135, 0.50); */}
          color: #FFF;
          text-align: center;
          font-family: Rubik-Medium;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          border:none;
          flex-shrink: 0;
        }
        .fav-icon{
          border-radius: 3px;
          background:  rgba(0, 92, 135, 0.05);
          padding:13px;
          margin-left:15px;
        }
      }
    }
  }

  .col-2{
    background:${({background})=> background ? `linear-gradient(90deg, #FFF 0%, rgba(255, 255, 255, 0.00) 93.33%),url(${ImageUrl}/${background})` : 'red'};
    background-size : cover;
    background-repeat : no-repeat;
    background-size: 100% 100%;
    width:750px;
    height:450px;
    border-radius: 0px 4px 4px 0px;
    .pick{

      margin-right:16px;

      .title{
      color: #005C87;
      text-align: center;
      font-family: Rubik-Medium;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }

    }
  }

  .imageContainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
  }

  .imageWrapper{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px;
    margin-right: 10px;
  }
}
`

const Title = styled.div`
.title{
  color: var(--Navy-blue, #005C87);
text-align: center;
font-family: Rubik-Medium;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 24px;
text-transform: capitalize;
}
`

const ProgramDetailContainer = styled.div`
width:1248px;
margin:20px auto;
background:white;
padding:20px;
border-radius:4px;

.main-container{
  display:flex;
  justify-content:space-between;
}

.left-container{
  margin-right:20px;
  width:60%;
}

.right-container{
  width:40%;
}

.title{
  color: #005C87;
  font-family: Rubik-Medium;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-bottom:20px;
  text-transform:capitalize;
}

.sub-title{
  color: #005C87;
  font-family: Rubik-Medium;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-bottom:12px;

}

.about{
  color:#005C87;
font-family: Rubik-Regular;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px;
}

.purchase-btn{
  border-radius: 4px;
background:  #007AB1;
height: 48px;
padding: 12px 20px;
color:  #FFF;
text-align: center;
font-family: Rubik-Medium;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 20px;
width:100%;
cursor:pointer;
}

.month-container{
  display:flex;
  justify-content:space-between;
  align-items:center;
  margin-top:20px;
  cursor:pointer;
}

.month{
  color:  #005C87;
font-family: Rubik-Medium;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 20px;
${'' /* margin-bottom:22px; */}
}

.week{
  color: #005C87;
font-family: Rubik-regular;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px; 
}
`

export { SliderContainer, Layout,Container, RecommendedContainer, Title, ProgramDetailContainer}