import React, { useState, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Container, Layout, RecommendedContainer, SliderContainer } from './style';
import Slider from 'react-slick';
import { arrowIconDouble } from '../../utils/icons';
import SkeletonLoder from '../common/skeletonLoder';
// import { ImageUrl } from '../../utils/constants';
// import LazyImage from '../common/LazyImage/LazyImage';
import parse from 'react-html-parser';

const RecommendedCoaching = ({ data, t, RecommendedProgramDataLoading,themesHeader, history }) => {
  const [currentSlide, setCurrentSlide] = useState(1);
  const sliderRef = useRef(null);

  const handleBeforeChange = (oldIndex, newIndex) => {
    setCurrentSlide(newIndex + 1);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    draggable: true,
    adaptiveHeight: true,
    nextArrow: <div className="left-arrow slick-next" />,
    prevArrow: <div className="left-arrow slick-prev" />,
    beforeChange: handleBeforeChange,
    responsive: [
      {
        breakpoint: 1252,
        settings: {
          dots: true,
          infinite: true,
          speed: 500,
          draggable: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          dots: true,
          infinite: true,
          speed: 500,
          draggable: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
    ],
  };

  return (
    <>
      {data?.length > 0 ? <Layout margin="0 0 5px 0">
        <div className="headingMain">
          <div className="heading">
            {t(`Recommended Coaching Program: ${themesHeader?.month}’s ${themesHeader?.theme_text}`)}
          </div>
          {data?.length > 0 ? <div className="arrows">
            <div
              className="iconBackground"
              onClick={() => sliderRef.current.slickPrev()}
              style={{ transform: "rotate(180deg)" }}
            >
              {arrowIconDouble()}
            </div>
            <div className="text">
              {currentSlide} / {data?.length}
            </div>
            <div
              className="iconBackground"
              onClick={() => sliderRef.current.slickNext()}
            >
              {arrowIconDouble()}
            </div>
          </div> : null}
        </div>
        {RecommendedProgramDataLoading ? (
          <React.Fragment>
            {" "}
            <SkeletonLoder
              width={"1248px"}
              height={"450px"}
              style={{ margin: "20px 0px 20px 0px" }}
            ></SkeletonLoder>
          </React.Fragment>
        ) : (<Container>
          {data?.length > 0 && (
            <SliderContainer
              display="flex"
              content="center"
              shadow="0px 8px 25px 0px #0D427040"
            >
              <Slider {...settings} ref={sliderRef}>
                {data?.map((item, index) => (
                  <div key={index}>
                    <RecommendedContainer background={item?.image ? item?.image : "white"}>
                      <div className="row">
                        <div className="col-1">
                          <div className="details">
                            <div className="title">
                              <span className="title-text">{item?.title}</span>
                            </div>
                            {/* <div className="description" dangerouslySetInnerHTML={{ __html: item?.about }} /> */}
                            <div className="description">{parse(item?.about)}</div>
                            <div className="moving-btn">
                              <button onClick={() => {history.push(`/company/program-detail/${item?.id}`)}}>
                                {/* <img src={ImageUrl+"/event-section/rocket.svg"} style={{margin:"0 12px 0 0px"}}/> */}
                                {"Start Program"}
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="imageContainer">
                            <div className="imageWrapper">
                              {/* {this.props.data.map((item, index) => (
                                  <div
                                    key={index}
                                    onClick={() => {
                                    //   setActiveRecommendation(item);
                                    //   setActiveIndex(index);
                                    }}
                                    style={{ marginLeft: "15px" }}
                                  >
                                    <LazyImage
                                      style={{
                                        width: "120px",
                                        height: "70px",
                                        borderRadius: "6px",
                                        // border: activeIndex === index && "2px solid white",
                                        boxShadow: "0px 8px 25px 0px rgba(0, 92, 135, 0.20)",
                                      }}
                                      src={ImageUrl + "/" + item.image}
                                    />
                                  </div>
                                ))} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </RecommendedContainer>
                  </div>
                ))}
              </Slider>
            </SliderContainer>
          )}
        </Container>)}
      </Layout> : <div className="noData">{t("No Data Found")}</div>}       
    </>
  );
};

RecommendedCoaching.propTypes = {
  data: PropTypes.array,
  history: PropTypes.object.isRequired,
  t: PropTypes.func,
  RecommendedProgramDataLoading: PropTypes.bool,
  themesHeader: PropTypes.object,
};

const mapStateToProps = (state) => ({
  themesHeader: state.profileData.themesHeader
});

const mapDispatchToProps = (/* dispatch */) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RecommendedCoaching));
