
import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { TabContainer, TabView } from "../ChallengeDetailsV2/styles";
import { Container } from "../ChallengeDashboardV2/CompletedChallenge/styles";
import Coaching from "./coaching";

import { DashboardLayout, OverlayDashboard } from "../CompanyEvents/styles";


const CompanyCoachingProgram = (props) => {
  const {history,companyInfo} = props;
  const [selectedTabId, setselectedTabId] = useState(1);

  useEffect(()=>{
    window.console.log("history.location.pathname",history.location.pathname)
    if(history.location.pathname === "/company/events"){
      setselectedTabId(1)
    }else if(history.location.pathname === "/company/events/completed"){
      setselectedTabId(2)
    }
  },[])



  const memoizedOverviewProps = useMemo(() => ({
    history,
    companyId: companyInfo?.id,
  }), [history, companyInfo?.id]);

  const MemoizedOverview = useMemo(() => React.memo(Coaching), []);

  return (
    <Container>
      <div className="mainContainer">
        <OverlayDashboard>
          <DashboardLayout>
            <TabContainer marginBottom="25px">
              <TabView
                width
                padding
                active={selectedTabId === 1}
                onClick={() =>{history.push("/company/program");setselectedTabId(1)}}
              >
                {"Coaching"}
              </TabView>
            </TabContainer>
            {selectedTabId === 1 && (
              <MemoizedOverview {...memoizedOverviewProps} />
            )}
          </DashboardLayout>
        </OverlayDashboard>
      </div>
    </Container>
  );
};

CompanyCoachingProgram.defaultProps = {
  companyInfo: { id: 1, name: "" },
};

CompanyCoachingProgram.propTypes = {
  companyInfo: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
  history: PropTypes.object,
  selectedTabId: PropTypes.number,
};

const mapStateToProps = (state) => ({
  role: state.auth.role,
});

const mapDispatchToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyCoachingProgram);
