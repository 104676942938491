/* eslint-disable no-console */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { GetHealthProgramsList } from "../../redux/constants/apiConstants";
import { toast } from "react-toastify";
import { fetchApi } from "../../utils/methods";
import RecommendedCoaching from "./recommendedCoaching";

const Coaching = ({history}) => {
  const [RecommendedEventData, setRecommendedEventData] = React.useState([]);
  const [RecommendedProgramDataLoading, setRecommendedProgramDataLoading] = React.useState(false);

  useEffect(() => {
    !RecommendedEventData.length && GetRecommendedEvents();
  }, []);

  const GetRecommendedEvents = async () => {
    setRecommendedProgramDataLoading(true);
    const apiUrl = GetHealthProgramsList ;
    try {
      const res = await fetchApi(apiUrl, "GET");
      if (res.status === "success") {
        setRecommendedProgramDataLoading(false);
        setRecommendedEventData(res?.data);
      } else {
        toast.error(res.error);
        setRecommendedProgramDataLoading(false);
      }
    } catch (error) {
      window.console.log(error);
    }
  };


  return (
    <>

      <RecommendedCoaching data={RecommendedEventData} RecommendedProgramDataLoading={RecommendedProgramDataLoading} history={history}/>
       
    </>
  );
};

Coaching.propTypes = {
  EngagementData: PropTypes.object.isRequired,
  companyId: PropTypes.number,
  selectedYearInfo: PropTypes.number,
  EngagementDataLoading: PropTypes.bool,
  history: PropTypes.object.isRequired,
};

export default connect(null, null)(Coaching);
